import React, { useState, useEffect } from 'react';
import { siteSetting as portalSiteSetting, siteZones as portalSiteZones } from 'services/loginService';
import { setActiveDbName } from 'services/callcenterService';
import _debounce from 'lodash.debounce';

const CallContext = React.createContext(null);
export { CallContext };

const CallContextContainer = props => {
  const [callcenterActiveDb, setActiveDb] = useState(null);
  const [isCenterOnCall, setCenterOnCall] = useState(false);
  const [isCenerTakingCall, setIsCenterTakingCall] = useState(false);
  const [basesSettings, setBasesSettings] = useState({});

  useEffect(() => {
    if (callcenterActiveDb) {
      setActiveDbName(callcenterActiveDb, basesSettings[callcenterActiveDb]);
    } else {
      setActiveDbName(false);
    }
  }, [callcenterActiveDb]);

  const setCenterTakingCall = _debounce((status) => {
    setIsCenterTakingCall(status);
  }, 30, {leading: true, trailing: false, maxWait: 50});

  const handlePickCall = (dbname) => {
    if (!isCenterOnCall) {
      setCenterOnCall(true);
      setActiveDb(dbname);
    }
  }

  const handleHangupCall = () => {
    setCenterOnCall(false);
  }

  const handleSetActiveDb = (dbname) => {
    if (!isCenterOnCall) {
      if (callcenterActiveDb === dbname) {
        setActiveDb(false);
      } else {
        setActiveDb(dbname);
      }
    }
  }

  const getCallcenterActiveDbSetting = () => callcenterActiveDb ? basesSettings?.[callcenterActiveDb] : null;

  const getCallcenterActiveDbZones = () => getCallcenterActiveDbSetting()?.zones || null;

  const siteSetting = () => getCallcenterActiveDbSetting() || portalSiteSetting();

  const siteZones = () => getCallcenterActiveDbZones() || portalSiteZones();

  return (
    <CallContext.Provider
      value={{
        callcenterActiveDb,
        isCenterOnCall,
        handlePickCall,
        handleHangupCall,
        handleSetActiveDb,
        isCenerTakingCall,
        setCenterTakingCall,
        basesSettings,
        setBasesSettings,
        getCallcenterActiveDbSetting,
        siteSetting,
        siteZones,
      }}
    >
      {props.children}
    </CallContext.Provider>
  );
};

export default CallContextContainer;
