import styled from 'styled-components';
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

export const DialogTitleContainer = styled(DialogTitle)`
  h2.MuiTypography-root {
    display: flex;
    justify-content: space-between;
  }
`;

export const DialogContentContainer = styled(DialogContent)`
  .dialog-content--wrapper {
    .card-body {

    }
  }
`;

export const DialogActionsContainer = styled(DialogActions)`
  button {
    min-width: 150px;

    @media (max-width: 576px) {
      min-width: 0;
      width: 100%;
    }
  }
`;