/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import {
  Button,
  Dialog,
} from '@material-ui/core';
import { AutoComplete } from '@material-ui/lab';

import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import AirplanemodeActiveTwoToneIcon from '@material-ui/icons/AirplanemodeActiveTwoTone';

import { MyCardRow, MyCardSub } from 'components/Elements/MyCard';
import MyButton from 'components/Elements/MyButton';
import MyAutoComplete from 'components/Elements/MyAutoComplete';
import MyInput from 'components/Elements/MyInput';
import MyDescription from 'components/Elements/MyDescription';

import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { NotificationContext } from 'components/NotificationOverlay';

import Config from 'config/index';
import { notNull, cleanPhone } from 'utils/text';
import { logout, siteSetting } from 'services/loginService';
import { airFlightService } from 'services/airFlightService';
import { DialogTitleContainer, DialogContentContainer, DialogActionsContainer } from './style';

const moment = require('moment-timezone');

const FlightModal = ({
  flightEditOpen,
  updateFlightInfo,
  handleFlightEditClose,
  apiToken,
  timezone,
}) => {
  if (!flightEditOpen) {
    return null;
  }

  const [values, setValues] = useState(null);
  const [isValid, setValid] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [flightDetail, setFlightDetail] = useState(null);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { showNotification } = useContext(NotificationContext);

  const handleCheckFlight = async (iata_code = null, flight_number = null) => {
    if ((iata_code || values?.iata_code) && (flight_number || values?.flight_number)) {
      showCircularProgress('Checking...');
      const data = await airFlightService.get_flights({ airline_iata: iata_code || values?.iata_code, flight_number: flight_number || values?.flight_number, token: apiToken });
      hideCircularProgress();
      if (data && data.data?.data?.data?.length > 0) {
        setFlightDetail({ ...data.data.data.data[0] });
        setValid(!iata_code);
      } else {
        setFlightDetail(null);
        setValid(false);
      }
    }
  }

  useEffect(() => {
    if (flightEditOpen) {
      setFlightDetail(null);
      setValues({
        ...flightEditOpen,
      });
      setValid(false);

      if (flightEditOpen.iata_code && flightEditOpen.flight_number) {
        handleCheckFlight(flightEditOpen.iata_code, flightEditOpen.flight_number);
      }
    } else {
      setValues(null);
      setFlightDetail(null);
    }
  }, [flightEditOpen]);

  const handleDetailChange = (id, newValue) => {
    setValues({
      ...values,
      [id]: newValue,
    });
    setValid(false);
  }

  const handleSave = () => {
    if (!isValid) {
      return;
    }
    updateFlightInfo({
      ...values,
      flight_date: flightDetail?.flight_date,
    });
  }

  const handleRemove = () => {
    updateFlightInfo({
      type: values.type
    });
  }

  const handleLoadAirlines = async ({ search }) => {
    if (search?.length >= 3) {
      const data = await airFlightService.get_airlines({ search, token: apiToken });
      if (data && data.data?.data?.data?.length > 0) {
        const formatted = data.data.data.data.map(item => ({ ...item, title: item.airline_name }));
        return formatted;
      }
    }
    return [];
  }

  const handleAirlineChange = airline => {
    setValues({
      ...values,
      airline_name: airline?.airline_name || null,
      iata_code: airline?.iata_code || null,
      airline,
    });
  }

  const timeConvert = t => t ? moment(t).tz(timezone || siteSetting().timezone).format('dddd, MMMM Do YYYY, h:mm A') : '';

  return (
    <Dialog
      classes={{ paper: 'modal-content' }}
      fullWidth
      maxWidth="sm"
      open={flightEditOpen}
      onClose={handleFlightEditClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitleContainer className="bg-secondary">
        <span>
          Flight Information
        </span>
      </DialogTitleContainer>
      <DialogContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper bg-secondary border-0">
          <div className="card-body px-lg-4 py-lg-3">
            <div className="card-body--column w-100">

                <MyCardRow>
                  <MyAutoComplete
                    label="Airline"
                    loadDataCallback={handleLoadAirlines}
                    onChange={handleAirlineChange}
                    defaultValue={values?.airline_name ? { ...values, title: values.airline_name } : null}
                  />
                  <MyInput
                    label="Flight Number"
                    id="flight_number"
                    type="number"
                    values={values}
                    onChange={handleDetailChange}
                  />
                </MyCardRow>
                <MyCardRow>
                  <div />
                  <MyButton
                    type="info"
                    onClick={() => handleCheckFlight()}
                  >
                    <span className="btn-wrapper--icon">
                      <AirplanemodeActiveTwoToneIcon fontSize="small" />
                    </span>
                    <span className="btn-wrapper--label">Check Flight</span>
                  </MyButton>
                  <div />
                </MyCardRow>
                <MyCardRow>
                  <MyDescription>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><b>Flight Date:</b> <span>{timeConvert(flightDetail?.flight_date)}</span></p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><b>Departure Airport:</b> <span>{flightDetail?.departure?.airport || ''}</span></p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><b>Departure Estimated:</b> <span>{timeConvert(flightDetail?.departure?.estimated)}</span></p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><b>Arrival Airport:</b> <span>{flightDetail?.arrival?.airport || ''}</span></p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><b>Arrival Terminal:</b> <span>{flightDetail?.arrival?.terminal || ''}</span></p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><b>Arrival Baggage:</b> <span>{flightDetail?.arrival?.baggage || ''}</span></p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><b>Arrival Delay:</b> <span>{flightDetail?.arrival ? `${flightDetail?.arrival?.delay || 0} min` : ''}</span></p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><b>Arrival Scheduled:</b> <span>{timeConvert(flightDetail?.arrival?.scheduled)}</span></p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}><b>Arrival Estimated:</b> <span>{timeConvert(flightDetail?.arrival?.estimated)}</span></p>
                  </MyDescription>
                </MyCardRow>
            </div>
          </div>
        </div>
      </DialogContentContainer>
      <DialogActionsContainer className="bg-secondary p-3">
        <Button
          size="small"
          className="btn-primary mxr-4 px-4 py-2 btn-transition-none"
          onClick={handleSave}
          disabled={!isValid}
        >
          <span className="btn-wrapper--icon">
            <SaveTwoToneIcon fontSize="small" />
          </span>
          <span className="btn-wrapper--label">Save</span>
        </Button>
        <Button
          size="small"
          className="btn-danger mxr-4 px-4 py-2 btn-transition-none"
          onClick={handleRemove}
        >
          <span className="btn-wrapper--icon">
            <DeleteTwoToneIcon fontSize="small" />
          </span>
          <span className="btn-wrapper--label">Remove</span>
        </Button>
        <Button
          size="small"
          className="btn-warning px-4 py-2 btn-transition-none"
          onClick={handleFlightEditClose}
        >
          <span className="btn-wrapper--icon">
            <ReplayTwoToneIcon fontSize="small" />
          </span>
          <span className="btn-wrapper--label">Close</span>
        </Button>
      </DialogActionsContainer>
    </Dialog>
  );
};

export default FlightModal;
