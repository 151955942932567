/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';

const base_url = `${process.env.BACKEND_API_BASE_URL}/twilio.php`;

export const twilioService = {
  create_standalone: async data =>
    axios.get(base_url, {
      params: {
        method: 'create_standalone',
        ...data,
        token: getLoginUser().token,
      },
    }),
  get_voice_token: async () =>
    axios.get(base_url, {
      params: {
        method: 'get_voice_token',
        token: getLoginUser().token,
      },
    }),
  get_call_queue: async () =>
    axios.get(base_url, {
      params: {
        method: 'get_call_queue',
        token: getLoginUser().token,
      },
    }),
  take_voice_call: async () =>
    axios.get(base_url, {
      params: {
        method: 'take_voice_call',
        token: getLoginUser().token,
      },
    }),
};
