import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import WrapWithContainer from '../CommonWrapper';

import { MyAutoCompleteContainer } from './style';

const MyAutoComplete = ({ label, defaultValue, onChange, loadDataCallback, ...otherProps }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const handleInputChange = async (value) => {
    setLoading(true);
    const data = await loadDataCallback({ search: value });
    if (data) {
      setOptions([...data]);
    }
    setLoading(false);
  }

  const handleChange = value => {
    if (onChange) {
      onChange(value);
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setOptions([{ ...defaultValue }]);
    }
  }, [defaultValue]);

  return (
    <MyAutoCompleteContainer>
      <Autocomplete
        id="asynchronous-demo"
        sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        {...(defaultValue && { defaultValue })}
        onInputChange={(e, value) => handleInputChange(value)}
        onChange={(e, value) => handleChange(value)}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={(option) => option.title}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </MyAutoCompleteContainer>
  )
};

export default WrapWithContainer(MyAutoComplete);