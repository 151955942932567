import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { notNull } from 'utils/text';
import { MyRadioGroupContainer, MyRadioContainer } from './style';
import WrapWithContainer from '../CommonWrapper';

const MyRadioGroup = ({id, value, values, onChange = () => {}, children, ...otherProps}) => (
  <MyRadioGroupContainer>
    <RadioGroup
      value={notNull(value) ? value : (values && notNull(values[id]) ? values[id] : '')}
      onChange={(e) => onChange(id, e.target.value)}
      {...otherProps}
    >
      {children}
    </RadioGroup>
  </MyRadioGroupContainer>
);

export const MyRadio = ({ id, label, value, disabled, ...otherProps }) => (
  <MyRadioContainer {...otherProps}>
    <FormControlLabel
      control={
        <Radio
          label={label}
          value={value}
          color="primary"
          disabled={disabled}
        />
      }
      label={label}
    />
  </MyRadioContainer>
);

export default WrapWithContainer(MyRadioGroup);