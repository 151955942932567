/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';
import { isCallcenterLoggedIn, getCallcenterUser } from './callcenterService';

const baseUrl = `${process.env.BACKEND_API_BASE_URL}/locations.php`;

const getToken = () => (isCallcenterLoggedIn() ? getCallcenterUser() : getLoginUser()).token;

export const locationsService = {
  get_prev_locations: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_prev_locations',
        token: getToken(),
        ...data, // { passenger_id, location_type, dbname }
      },
    }),
  check_block: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'check_block',
        token: getToken(),
        ...data, // { place_name, address, city, zip, state, dbname }
      },
    }),
  admin_get_block_table: async (filter, order) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_block_table',
        ...filter,
        order,
        token: getLoginUser().token,
      },
    }),
  admin_add_block: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_add_block',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_del_block: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_del_block',
        id,
        token: getLoginUser().token,
      },
    }),
};
