/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';
import { isCallcenterLoggedIn, getCallcenterUser } from './callcenterService';

const baseUrl = `${process.env.BACKEND_API_BASE_URL}/passengers.php`;

const getToken = () => (isCallcenterLoggedIn() ? getCallcenterUser() : getLoginUser()).token;

export const passengersService = {
  get_passenger: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_passenger',
        token: getToken(),
        ...data, // { phone, dbname }
      },
    }),
  enable_passenger: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'enable_passenger',
        token: getToken(),
        ...data, // { phone, dbname }
      },
    }),
  create_passenger: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'create_passenger',
        token: getToken(),
        ...data,
      },
    }),
  admin_get_passengers_table: async (filter, order, page, page_size) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_passengers_table',
        ...filter,
        order,
        page,
        page_size,
        token: getLoginUser().token,
      },
    }),
  admin_get_passenger_detail: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_passenger_detail',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_edit: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_edit',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_del: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_del',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_export_table: async filter =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_export_table',
        ...filter,
        token: getLoginUser().token,
      },
      responseType: 'blob',
    }),
};
