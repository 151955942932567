/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';

const base_url = `${process.env.BACKEND_API_BASE_URL}/vehicles.php`;

export const vehiclesService = {
  get_vehicle_types: async () =>
    axios.get(base_url, {
      params: {
        method: 'get_vehicle_types',
        token: getLoginUser().token,
      },
    }),
  admin_get_vehicles_table: async (filter, order, page, page_size) =>
    axios.get(base_url, {
      params: {
        method: 'admin_get_vehicles_table',
        ...filter,
        order,
        page,
        page_size,
        token: getLoginUser().token,
      },
    }),
  admin_get_vehicle_detail: async id =>
    axios.get(base_url, {
      params: {
        method: 'admin_get_vehicle_detail',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_edit: async data =>
    axios.get(base_url, {
      params: {
        method: 'admin_edit',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_del: async id =>
    axios.get(base_url, {
      params: {
        method: 'admin_del',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_export_vehicles_table: async filter =>
    axios.get(base_url, {
      params: {
        method: 'admin_export_vehicles_table',
        ...filter,
        token: getLoginUser().token,
      },
      responseType: 'blob',
    }),
  back4app_get_vehicle_types: async vehicleMake => {
    const where = encodeURIComponent(
      JSON.stringify({
        Make: vehicleMake,
      })
    );

    return axios.get(
      `https://parseapi.back4app.com/classes/Car_Model_List?limit=10000&excludeKeys=Make&where=${where}`,
      {
        headers: {
          'X-Parse-Application-Id': 'oJE2LJQI2Q5tqaLyIXvLwfAeSjnJbQnVprWLjhkh', // This is the fake app's application id
          'X-Parse-Master-Key': 'bG3pEnZ1t0fCHIGsu0WjL6IOWxb2I7BNHe6kvCWk', // This is the fake app's readonly master key
        },
      }
    );
  },
  decode_vin: async vin => axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${vin}?format=json`),
};
