/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const MyAutoCompleteContainer = styled.div`
  .MuiTextField-root {
    height: 45px;

    .MuiInputLabel-outlined {
      transform: translate(14px, 13px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }

    .MuiAutocomplete-inputRoot {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
`;
