/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';
import { isCallcenterLoggedIn, getCallcenterUser } from './callcenterService';

const baseUrl = `${process.env.BACKEND_API_BASE_URL}/fare.php`;

const getToken = () => (isCallcenterLoggedIn() ? getCallcenterUser() : getLoginUser()).token;

export const fareService = {
  calc_fare: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'calc_fare',
        token: getToken(),
        ...data, // { distance, passenger_count, vehicle_type, baggage_count, trip_duration, zone_fare, dbname }
      },
    }),
};
