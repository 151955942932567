/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';

const base_url = `${process.env.BACKEND_API_BASE_URL}/flight.php`;

export const airFlightService = {
  get_airlines: async ({ search, limit, token }) => axios.get(base_url, {
    params: {
      method: 'get_airlines',
      search,
      limit,
      token: token || getLoginUser().token,
    },
  }),
  get_flights: async ({ airline_iata, flight_number, limit, token }) =>axios.get(base_url, {
    params: {
      method: 'get_flights',
      limit,
      airline_iata,
      flight_number,
      token: token || getLoginUser().token,
    },
  }),
};
